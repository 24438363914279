
.font-movie-title{
	font-family: 'Economica', sans-serif;
  }
.font-link-header{
	font-family: 'Montserrat', sans-serif;
	font-size:45px;
}
.font-link-fav{
	font-family: 'Assistant', sans-serif;
}
.font-link-favs{
	font-family: 'Montserrat', sans-serif;
	font-size:25px;
}

html {
    overflow: scroll;
    overflow-x: visible;
	
}
::-webkit-scrollbar {
    width:0;
	  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
	

}

body {
	background: #141414;
	color: #ffffff;

}

.movieListrow{
	overflow: hidden;
	overflow-x: auto;
	flex-wrap:nowrap;
}

.headerContainer{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-around;
	padding: 20px;
	margin-top: 10px;
	margin-bottom: 10px;
}

.image-container{
	position:relative;
	transition: transform 0.2s;
}

.image-container:hover{
	cursor: pointer;
	transform: scale(1.1);
}

.overlay{
	position: absolute;
	background: rgba(0, 0, 0, 0.8);
	width: 250px;
	transition:0.5s ease;
	opacity: 0;
	bottom: 0;
	font-size: 20px;
	padding: 10px;
	text-align: center;
}

.image-container:hover .overlay{
	opacity: 1;
}
.FavouriteContainer{
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: start;
	padding: 10px;
	margin-top: 20px;
	margin-bottom: 10px;
	

}
.favfontsize{
	font-size: 25px;
}

